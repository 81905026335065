import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "../components/background-image"
import officeImage from "../images/office.jpg"

import contactStyles from "../styles/contact.module.scss"

const ContactPage = () => {
  var [name, setName] = useState("")
  var [email, setEmail] = useState("")
  var [phone, setPhone] = useState("")
  var [comment, setComment] = useState("")
  var [reason, setReason] = useState("")
  var [formValidationFlag, setFormValidationFlag] = useState(false)

  const submitForm = e => {
    e.preventDefault()
    if (name && email && comment) {
      fetch("ntu.wxi.mybluehost.me/api/contact.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          comment: comment,
          reason: reason
        }),
      })
    } else {
      setFormValidationFlag(true)
    }
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <BackgroundImage
        color="rgba(0, 178, 255, 0.69)"
        image={officeImage}
        backgroundPosition="0% 35%"
        title="Get In Touch"
      />
      <div className={contactStyles.whiteBackground}>
        <div className="container">
          <h1 id="form">
            <span className="avoidWrap">Have questions?</span>{" "}
            <span className="avoidWrap">We'd love to help!</span>
          </h1>
          <div className={contactStyles.contactContainer}>
            <a href="tel:7277875290">
              <div className={contactStyles.contactBox}>
                <img src={require("../images/phone.svg")} alt="phone icon" />
                <h3>(727) 787-5290</h3>
              </div>
            </a>
            <a href="mailto:info@taxartist.com">
              <div className={contactStyles.contactBox}>
                <img src={require("../images/email.svg")} alt="email icon" />
                <h3>info@taxartist.com</h3>
              </div>
            </a>
          </div>
          <div className={contactStyles.mapContainer}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.353840311825!2d-82.67380734937713!3d28.044218717128658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2ec4257c0ee41%3A0x81e26263e18ac33b!2sDiMarco+%26+Associates%2C+CPAs!5e0!3m2!1sen!2sus!4v1552509604811"
            />
            <div>
              <form action="#" method="POST" encType="text/plain">
                <input
                  className={
                    name || !formValidationFlag
                      ? ""
                      : contactStyles.inputRedBorder
                  }
                  type="text"
                  id="name"
                  name="name"
                  placeholder="name"
                  onChange={e => setName(e.target.value)}
                />
                <input
                  className={
                    email || !formValidationFlag
                      ? ""
                      : contactStyles.inputRedBorder
                  }
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email"
                  onChange={e => setEmail(e.target.value)}
                />
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="phone"
                  onChange={e => setPhone(e.target.value)}
                />
                <textarea
                  className={
                    comment || !formValidationFlag
                      ? ""
                      : contactStyles.inputRedBorder
                  }
                  id="comment"
                  name="comment"
                  placeholder="what can we help you with?"
                  onChange={e => setComment(e.target.value)}
                />
                <input
                  type="text"
                  id="reason"
                  name="reason"
                  placeholder="reason"
                  onChange={e => setReason(e.target.value)}
                />
                <input type="submit" value="Send" onClick={submitForm} />
                {formValidationFlag ? (
                  <p>Please fill in the required fields.</p>
                ) : (
                  <p />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
